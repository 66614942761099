<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card :loading="loading">
        <v-toolbar dense elevation="0">
          <v-toolbar-title>Add User Perm</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="close()" title="Refresh">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-autocomplete label="User" outlined dense hide-details placeholder="User" class="mb-4" :items="users" item-text="username" item-value="id" v-model="user_id" :loading="loadingUsers" @change="getUserPerms()"></v-autocomplete>
          <!-- <v-autocomplete label="Perm" outlined dense hide-details placeholder="User" class="mb-4" :items="perms" item-text="perm_name" item-value="id" v-model="module_perm_id" :loading="loadingPerms"></v-autocomplete> -->

          <div style="height: 420px;overflow-y: auto;">
            <v-checkbox v-for="item in perms" :key="item.perm_name" :label="item.perm_name" v-model="item.perm_active" hide-details dense @change="saveData(item.id, !item.perm_active)"></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "module-add-perm",
  data() {
    return {
      loading: false,
      dialog: false,
      users: [],
      loadingUsers: false,
      user_id: null,
      perms: [],
      loadingPerms: false,
      module_perm_id: null,
      moduleId: null,
      callback: null,
    }
  },
  methods: {
    show(moduleId, callback = null) {
      this.moduleId = moduleId;
      this.dialog = true;
      this.callback = callback;
      this.getUsers();
    },
    close() {
      if (this.callback != null && typeof this.callback == "function") {
        this.callback();
      }
      this.dialog = false;
    },
    async getUsers() {
      this.loadingUsers = true;
      await this.$axios.get("module/users").then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.users = resData.data.users;

          // console.log(resData);
        }

        this.loadingUsers = false;
      });
    },

    async getPerms() {
      this.loadingPerms = true;
      var config = {
        params: {
          module_id: this.moduleId,
        }
      }
      await this.$axios.get("module/perms", config).then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.perms = resData.data.perms;

          // console.log(resData);
        }

        this.loadingPerms = false;
      });
    },

    async saveData(module_perm_id, perm_active) {
      this.loading = true;

      if (this.user_id == null || this.user_id.length == 0) {
        this.showAlert("error", "User ID is Required");
        this.loading = false;
        return;
      }
      if (this.moduleId == null || this.moduleId.length == 0) {
        this.showAlert("error", "Module ID is Required");
        this.loading = false;
        return;
      }
      if (module_perm_id == null || module_perm_id.length == 0) {
        this.showAlert("error", "Perm ID is Required");
        this.loading = false;
        return;
      }
      if (perm_active == null || perm_active.length == 0) {
        this.showAlert("error", "Toogle Value is Required");
        this.loading = false;
        return;
      }

      const uri = 'module/save-user-perm';
      const formData = new FormData();
      formData.append("user_id", this.user_id);
      formData.append("module_id", this.moduleId);
      formData.append("module_perm_id", module_perm_id);
      formData.append("perm_active", perm_active);
      await this.$axios.post(uri, formData)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.loading = false;

          this.getUserPerms();
        })
        .catch((e) => {
          this.loading = false;

          this.getUserPerms();

          this.axiosErrorHandler(e);
        });
    },

    async getUserPerms() {
      this.loadingPerms = true;
      var config = {
        params: {
          module_id: this.moduleId,
          user_id: this.user_id,
        }
      }
      await this.$axios.get("module/user-perms", config).then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.perms = resData.data.perms;

          // console.log(resData);
        }

        this.loadingPerms = false;
      });
    },
  }
}
</script>